





















































































































































































































































































































































































































































































































import DialogoDeEdicaoDeFornecedor from '@/components/fornecedor/DialogoDeEdicaoDeFornecedor.vue'
import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import { ImportacaoDeNota, ProdutoDaImportacao } from '@/models/ImportacaoDeNota'
import { fixarCasasDecimais, formatarCnpjOuCpf } from '@/shareds/formatadores'
import { obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FinalizarImportacaoDeNotaDoFornecedorUseCase, FindAtributoUseCase, FindGradeDeProdutosUseCase, ImportarNotaDoFornecedorUseCase } from '@/usecases'
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import ProdutoDaImportacaoDeNota from "./ProdutoDaImportacaoDeNota.vue"
import SeletorDeMarca from '@/components/produto/SeletorDeMarca.vue'
import { AtributoDoProduto, Produto, Variacao, Variante } from '@/models'
import SeletorDeTag from '@/components/produto/SeletorDeTag.vue'
import SeletorDeCategoria from '../produtos/EdicaoDeProduto/SeletorDeCategoria.vue'
import SeletorDeEstacao from '@/components/produto/SeletorDeEstacao.vue'
import SeletorDeGenero from '@/components/produto/SeletorDeGenero.vue'
import { round } from '@/shareds/number-utils'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import { uploadFilesToS3 } from '@/shareds/s3/files'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import DialogoDeSelecaoDeGradeDeProdutos from '@/components/produto/DialogoDeSelecaoDeGradeDeProdutos.vue'
import cartesianProduct from 'cartesian-product'
import SeletorDeNcm from '@/components/ui/SeletorDeNcm.vue'

const IMPORTACAO_DE_NOTA = 'IMPORTACAO_DE_NOTA'

@Component({
	components: {
		BuscaDeProdutoDropdown,
		DialogoDeSelecaoDeGradeDeProdutos,
		DialogoDeEdicaoDeFornecedor,
		ProdutoDaImportacaoDeNota,
		SeletorDeMarca,
		SeletorDeTag,
		SeletorDeCategoria,
		SeletorDeEstacao,
		SeletorDeGenero,
		CampoDePercentual,
		Confirmacao,
		SeletorDeNcm,
	},
})
export default class TelaDeImportacaoDeNota extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() dialogoDeFornecedor!: DialogoDeEdicaoDeFornecedor
	@Ref() dialogoDeSelecaoDeGradeDeProdutos!: DialogoDeSelecaoDeGradeDeProdutos
	@Ref() confirmarAlteracaoDaMargemDeLucro!: Confirmacao
	@Ref() campoDeMargemDeLucro!: CampoDecimal

	AlertModule = AlertModule

	importarNotaDoFornecedorUseCase = new ImportarNotaDoFornecedorUseCase()
	finalizarImportacaoDeNotaDoFornecedorUseCase = new FinalizarImportacaoDeNotaDoFornecedorUseCase()
	findGradeDeProdutosUseCase = new FindGradeDeProdutosUseCase()
	findAtributosUseCase = new FindAtributoUseCase()

	formatarCnpjOuCpf = formatarCnpjOuCpf
	obrigatorio = obrigatorio
	fixarCasasDecimais = fixarCasasDecimais
	file: File | null = null
	carregando = false
	criandoFornecedor = false
	enviando = false
	importacaoDeNota: ImportacaoDeNota | null = localStorage[IMPORTACAO_DE_NOTA]
		? JSON.parse(localStorage[IMPORTACAO_DE_NOTA])
		: null
	mostrarDialogoDeEdicaoDeProduto = false
	produtoEmEdicao: Produto = {} as Produto
	indiceEmEdicao = 0
	margemDeLucroGeral = 0
	naoDeveMovimentarEstoque = false
	gerandoVariantes = false
	podeFinalizar = false

	headersComCriacaoDeProduto = [
		{ text: 'Nome', value: 'nota.xProd' },
		{ text: 'Marca', value: 'encontrado.marca.nome' },
		{ text: 'Cod. do fornecedor', value: 'nota.cProd'},
		{ text: 'Quantidade', value: 'nota.qCom'},
		{ text: 'Valor un.', value: 'nota.vUnCom'},
		{ text: 'Valor total', value: 'nota.vProd'},
		{ text: 'Preço de venda varejo', value: 'precoFinalVarejo'},
		{ text: 'Margem de lucro', value: 'margemDeLucro' },
		{ text: 'Produto associado', value: 'encontrado' },
	]

	headersSemCriacaoDeProduto = [
		{ text: 'Nome', value: 'nota.xProd' },
		{ text: 'Cod. do fornecedor', value: 'nota.cProd'},
		{ text: 'Quantidade', value: 'nota.qCom'},
		{ text: 'Valor un.', value: 'nota.vUnCom'},
		{ text: 'Valor total', value: 'nota.vProd'},
		{ text: 'Produto associado', value: 'encontrado' },
	]

	get podeCriarProduto() {
		return (this.importacaoDeNota && this.importacaoDeNota.lojaDestinoDaImportacao.configuracaoDaLoja &&
											this.importacaoDeNota.lojaDestinoDaImportacao.configuracaoDaLoja.criarProdutoImportacaoEntrada)
	}

	verificaSeCriaProduto(item, importacaoDeNota) {
		return (item.encontrado && item.encontrado.id) 
			|| importacaoDeNota 
			&& (importacaoDeNota.lojaDestinoDaImportacao.configuracaoDaLoja && !importacaoDeNota.lojaDestinoDaImportacao.configuracaoDaLoja.criarProdutoImportacaoEntrada)
	}

	quantidadesInformadasValidas() {
		if (!this.importacaoDeNota) return true
		if (this.importacaoDeNota.produtos.filter(produto => produto.encontrado && produto.encontrado.tipo === 'Com Variantes').length === 0) return true

		const quantidadeInformadaTotal = this.importacaoDeNota.produtos.filter(produto => produto.encontrado && produto.encontrado.tipo === 'Com Variantes').map(produto => produto.quantidadeInformada||0).reduce((total, valor) => total + valor)
		const quantidadeEsperadaTotal = this.importacaoDeNota.produtos.filter(produto => produto.encontrado && produto.encontrado.tipo === 'Com Variantes').map(produto => parseInt(produto.nota.qCom)||0).reduce((total, valor) => total + valor)
		return quantidadeInformadaTotal === quantidadeEsperadaTotal
	}

	async importarNota() {
		try {
			this.carregando = true
			this.importacaoDeNota = await this.importarNotaDoFornecedorUseCase.execute(
				this.file,
				(fornecedor) => this.dialogoDeFornecedor.mostrar(fornecedor, true),
			)

			this.importacaoDeNota.produtos.forEach(produto => {
				if (produto.encontrado && !produto.encontrado.id && this.importacaoDeNota) {
					produto.encontrado.marca = this.importacaoDeNota.fornecedor.encontrado?.marca || null
				}
			})

			this.importacaoDeNota.produtos.forEach(produto => {
				if (produto.encontrado && produto.encontrado.tipo === 'Com Variantes') {
					produto.encontrado.variantes.sort((varianteA, varianteB) =>
						this.obterValorDoAtributo(varianteA, 'Tamanho').localeCompare(this.obterValorDoAtributo(varianteB, 'Tamanho')),
					)

					produto.encontrado.variantes.sort((varianteA, varianteB) =>
						this.obterValorDoAtributo(varianteA, 'Cor').localeCompare(this.obterValorDoAtributo(varianteB, 'Cor')),
					)
				}
			})

			for (let i = 0;i < this.importacaoDeNota.produtos.length; i++) {
				this.importacaoDeNota.produtos[i].indice = i
			}
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	created() {
		if (this.importacaoDeNota) {
			for (let i = 0;i < this.importacaoDeNota.produtos.length; i++) {
				this.importacaoDeNota.produtos[i].indice = i
			}
		}
	}

	get tiposDeProdutos(): BuscaDeProdutoDropdown['filtros'] {
		return { tipos: ['Padrão', 'Variante', 'Composto'] }
	}

	async salvarMapeamentoDeNotas() {
		if (!this.importacaoDeNota) return;
		if (this.importacaoDeNota.produtos.some(({ encontrado }) => !encontrado)) {
			AlertModule.setError('Existem produtos não associados');
			return;
		}
		if (!this.form.validate()) return;
		try {
			this.enviando = true;

			const importacaoDeNotaBlob = new Blob([JSON.stringify(this.importacaoDeNota)], { type: 'application/json' });
			const importacaoDeNotaFile = new File([importacaoDeNotaBlob], 'importacaoDeNota.json', { type: 'application/json' });

			const informacoesDosArquivos = await uploadFilesToS3(
				[importacaoDeNotaFile],
				`importacao-de-notas`,
			);
			const urlDoArquivoJson = informacoesDosArquivos.map(({ config }) => config.url)[0];

			if (!urlDoArquivoJson) {
				return AlertModule.setError("Ocorreu um erro interno, contate o suporte");
			}

			const importacaoDeNotaFinalizada = await this.finalizarImportacaoDeNotaDoFornecedorUseCase.execute(urlDoArquivoJson, this.naoDeveMovimentarEstoque);

			if (!importacaoDeNotaFinalizada.feedbackDosTitulos) {
				AlertModule.setSuccess('Nota importada com sucesso');
			} else {
				AlertModule.setInfo(`Nota importada com sucesso, porém ${importacaoDeNotaFinalizada.feedbackDosTitulos}`);
			}

			this.importacaoDeNota = null;
		} catch (error: any) {
			AlertModule.setError(error);
		} finally {
			this.enviando = false;
		}
	}

	limparIntegracaoDeNota() {
		localStorage.removeItem(IMPORTACAO_DE_NOTA)
		this.importacaoDeNota = null
	}

	async editarFornecedor() {
		if (!this.importacaoDeNota || !this.importacaoDeNota.fornecedor.encontrado) return
		const fornecedor = await this.dialogoDeFornecedor.mostrar(this.importacaoDeNota.fornecedor.encontrado, false)
		if (!fornecedor) return
		this.importacaoDeNota = {
			...this.importacaoDeNota,
			fornecedor: {
				...this.importacaoDeNota.fornecedor,
				encontrado: fornecedor,
			},
		}
	}

	@Watch('importacaoDeNota', { deep: true, immediate: true })
	onChangeIntegracaoDeNota() {
		if (this.importacaoDeNota) {
			this.importacaoDeNota.produtos.forEach(produto => {
				if (produto.encontrado && produto.encontrado.tipo === 'Com Variantes') {
					produto.quantidadeInformada = produto.encontrado.variantes ? produto.encontrado.variantes.map(variante => variante.quantidadeImportacao||0).reduce((total, valor) => total + valor) : 0
				} else {
					produto.quantidadeInformada = 0
				}
			})

			this.importacaoDeNota.quantidadesPreenchidasCorretamente = this.quantidadesInformadasValidas()
			this.onReloadFinalizar()
		}

		this.file = null
		localStorage.setItem(IMPORTACAO_DE_NOTA, JSON.stringify(this.importacaoDeNota))
	}

	async abrirDialogoDeEditarProduto(produto: ProdutoDaImportacao, indiceEmEdicao: number) {
		this.indiceEmEdicao = indiceEmEdicao

		this.produtoEmEdicao = JSON.parse(JSON.stringify(produto.encontrado))

		if (!produto.encontrado.precoCompra) {
			produto.encontrado.precoCompra = produto.encontrado.preco
			produto.encontrado.margemLucro = 0
		}

		this.produtoEmEdicao.margemLucro = produto.margemDeLucro
		this.produtoEmEdicao.precoCompra = produto.encontrado.precoCompra
		this.produtoEmEdicao.preco = produto.precoFinalVarejo
		this.produtoEmEdicao.ncm = {
			id: '',
			descricao: '',
			codigo: produto.nota.NCM,
		}

		this.mostrarDialogoDeEdicaoDeProduto = true
	}

	salvarProduto() {
		if (!this.importacaoDeNota) return

		this.importacaoDeNota.produtos[this.indiceEmEdicao].encontrado = JSON.parse(JSON.stringify(this.produtoEmEdicao))
		this.importacaoDeNota.produtos[this.indiceEmEdicao].margemDeLucro = this.produtoEmEdicao.margemLucro || 0
		this.importacaoDeNota.produtos[this.indiceEmEdicao].precoFinalVarejo = this.produtoEmEdicao.preco || 0

		this.fecharDialogoDeEdicaoDeProduto()
	}

	fecharDialogoDeEdicaoDeProduto() {
		this.produtoEmEdicao = {} as Produto
		this.indiceEmEdicao = 0
		this.mostrarDialogoDeEdicaoDeProduto = false
	}

	calculaPrecoVarejo() {
		if (!this.produtoEmEdicao) return
		
		const precoCompra = this.produtoEmEdicao.precoCompra || 0
		const margemDeLucro = 1 + (this.produtoEmEdicao.margemLucro || 0) / 100
		
		this.produtoEmEdicao.preco = round(
			(precoCompra * margemDeLucro ).toString(),
			2,
		)
	}

	formatarMenssagem() {
		return `
				<div><h2 class="formatarAviso">Aviso!</h2></div>
				<div class="mt-4">
					Você deseja continuar? 
					Se confirmar, todas as margens de lucro serão atualizadas
				</div>
			`
	}

	async mostrarConfimacao() {
		if(!this.importacaoDeNota) return
		if(!await this.confirmarAlteracaoDaMargemDeLucro.mostrar()) return
		
		this.inserirMargemDeLucroNosItens()
	}

	inserirMargemDeLucroNosItens() {
		if(!this.importacaoDeNota) return
		
		this.importacaoDeNota.produtos.forEach(produto => {
			produto.margemDeLucro = this.margemDeLucroGeral

			this.modificarMargemDeLucro(produto)
		})
	}

	formatarNumero(numero) {
		if(numero == null || numero == undefined) {
			return ''
		}
		return this.fixarCasasDecimais(parseFloat(numero), 2)
	}

	modificarMargemDeLucro(produto: ProdutoDaImportacao) {
		if (!produto) return

		if (produto.margemDeLucro === 0) {			
			produto.precoFinalVarejo = Number(produto.nota.vUnCom)
			return
		}

		const precoCompra = Number(produto.nota.vUnCom) || 0
		const margemDeLucro = 1 + (produto.margemDeLucro || 0) / 100

		produto.precoFinalVarejo = round(
			(precoCompra * margemDeLucro ).toString(),
			2,
		)
	}

	obterValorDoAtributo(variante, atributoEsperado) {
		const valorFiltrado =  variante.atributosVariante.filter(atributo => atributo.nome.toUpperCase() === atributoEsperado.toUpperCase())

		if (valorFiltrado.length > 0) {
			return valorFiltrado[0].valor
		}
		return 'Sem '+atributoEsperado
	}

	montarGradeDoProduto() {
		this.dialogoDeSelecaoDeGradeDeProdutos.mostrar()
	}

	async criarVariacoesDoProdutoEmEdicao(cores, tamanhos) {
		this.produtoEmEdicao.tipo = 'Com Variantes'

		if (this.produtoEmEdicao.tipo === 'Com Variantes') {
			try {
				this.gerandoVariantes = true
				const atributos = await this.findAtributosUseCase.findAll()
				
				const atributoCor = atributos.filter(atributo => atributo.nome.toUpperCase() === 'COR')[0]
				const atributoTamanho = atributos.filter(atributo => atributo.nome.toUpperCase() === 'TAMANHO')[0]

				this.produtoEmEdicao.atributosProduto = []

				this.produtoEmEdicao.atributosProduto.push({
					id: '',
					atributo: atributoCor,
					valores: cores,
					descontoVarejo: 0,
				} as AtributoDoProduto)

				this.produtoEmEdicao.atributosProduto.push({
					id: '',
					atributo: atributoTamanho,
					valores: tamanhos,
					descontoVarejo: 0,
				} as AtributoDoProduto)

				const variantes = this.criarVariantesDoProduto(Object.create(this.produtoEmEdicao), this.produtoEmEdicao.atributosProduto)

				this.produtoEmEdicao.variantes = variantes
			} catch(error) {
				AlertModule.setError(error)
			} finally {
				this.gerandoVariantes = false
			}
		}
	}

	removerEspacos(str) {
		return str.replace(/\s+/g, "")
	}

	criarVariantesDoProduto(
		produto: Produto,
		atributos: AtributoDoProduto[],
	) {
		const atributosDaVariante = atributos
			.filter(({ valores }) => valores.length)
			.map(({ id, atributo, valores }) =>
				valores.map(valor => ({
					id,
					nome: atributo.nome,
					valor,
				})),
			)
		const variacoes: Variacao[] = cartesianProduct(atributosDaVariante)
		const variantes = variacoes.map<Variante>(variacao => ({
			id: '',
			ncm: null,
			cest: null,
			regraFiscal: null,
			idProdutoPai: produto.id,
			tipo: "Variante",
			tags: produto.tags,
			descricao: null,
			categoria: produto.categoria,
			urlImagens: produto.urlImagens,
			sku: produto.sku
				+ this.removerEspacos(variacao.filter(vari => vari.nome.toUpperCase() === 'TAMANHO')[0].valor.toUpperCase())
				+ this.removerEspacos(variacao.filter(vari => vari.nome.toUpperCase() === 'COR')[0].valor.toUpperCase()),
			preco: null,
			pesoLiquido: produto.pesoLiquido,
			pesoBruto: produto.pesoBruto,
			descontoVarejo: null,
			marca: produto.marca,
			nome: produto.nome,
			atributosVariante: variacao,
			temGarantia: produto.temGarantia,
			regrasFiscais: [],
			eans: [],
			unidadeDeMedida: produto.unidadeDeMedida,
			identificadores: [],
			produtoDesativado: produto.produtoDesativado,
			produtoIntegrado: produto.produtoIntegrado,
			genero: produto.genero,
			fatorDeConversao: produto.fatorDeConversao,
			unidadeDeConversao: produto.unidadeDeConversao,
			estacao: produto.estacao,
			isValePresente: false,
			skuPai: produto.skuPai,
			quantidadeImportacao: 0,
			fornecedor: produto.fornecedor,
			precoCompra: null,
			margemLucro: null,
			nomeCompleto: produto.nome + ' ' + variacao.filter(vari => vari.nome.toUpperCase() === 'COR')[0].valor + '/' + variacao.filter(vari => vari.nome.toUpperCase() === 'TAMANHO')[0].valor,
		}))
		return variantes
	}

	async onReloadFinalizar() {
		console.log('this.importacaoDeNota2', !this.importacaoDeNota)
		if (!this.importacaoDeNota) return

		this.podeFinalizar = this.importacaoDeNota.produtos.filter(produto => produto.encontrado && produto.encontrado.tipo && produto.encontrado.tipo === 'Com Variantes').length > 0 
			&& !this.importacaoDeNota.quantidadesPreenchidasCorretamente
	}
}


































































































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import { FindGradeDeProdutosUseCase } from '@/usecases'
import { obrigatorio } from '@/shareds/regras-de-form'
import { nextTick } from '@/shareds/utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { GradeDeProdutos, ItemGradeDeProdutos } from '@/models'
import Axios, { CancelTokenSource } from 'axios'
import { obterPaginavelVazio } from '@/shareds/utils-paginacao'
import Confirmacao from '../ui/Confirmacao.vue'
import CampoDeTexto from '../ui/CampoDeTexto.vue'

type AbaDoFornecedor = 'Principal' | 'Contatos' | 'Grupos Econômicos'

@Component({
	components: {
		Confirmacao,
		CampoDeTexto,
	},
})
export default class DialogoDeSelecaoDeGradeDeProdutos extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() confirmacaoParaGerarVariantes!: Confirmacao
	$refs!: {
		[key: string]: HTMLFormElement | CampoDeTexto
	}
	salvando = false
	gradeSelecionada: GradeDeProdutos | null = null
	mostra = false
	findGradeDeProdutosUseCase = new FindGradeDeProdutosUseCase()
	obrigatorio = obrigatorio
	buscandoCnpjOuCpf = false
	errors: string[] = []
	tabs: AbaDoFornecedor[] = ['Principal', 'Contatos', 'Grupos Econômicos']
	tab: null | number = null
	criandoFornecedor = false
	cancelToken: CancelTokenSource | null = null
	gradesEncontradas: GradeDeProdutos[] = []
	coresAdicionadas: any[] = []
	tamanhosSelecionados: number[] = []

	async mostrar() {

		this.resetarDados()

		try {
			this.cancelToken = Axios.CancelToken.source()
			const gradesEncontradas = await this.findGradeDeProdutosUseCase.findAll(
				{
					parametros: {busca: ''},
					paginavel: obterPaginavelVazio(),
				}, {
					cancelToken: this.cancelToken.token,
				},
			)
			this.gradesEncontradas = gradesEncontradas.content
		} catch(error) {
			AlertModule.setError(error)
		}


		this.mostra = true
	}

	esconder() {
		this.mostra = false
		this.resetarDados()
	}

	resetarDados() {
		this.gradesEncontradas = []
		this.coresAdicionadas = []
		this.tamanhosSelecionados = []
		this.gradeSelecionada = null
	}

	async confirmarSelecao() {
		await nextTick()

		if (!this.gradeSelecionada) {
			AlertModule.setError('Selecione ao menos uma grade de tamanhos para prosseguir')
			return
		}

		if (!this.coresAdicionadas || this.coresAdicionadas.length === 0) {
			AlertModule.setError('Informe ao menos uma cor para prosseguir')
			return
		}

		if (!this.tamanhosSelecionados || this.tamanhosSelecionados.length === 0) {
			AlertModule.setError('Selecione ao menos um tamanho para prosseguir')
			return
		}

		if (this.coresAdicionadas.filter(corAdicionada => corAdicionada.descricao === '' || !corAdicionada).length > 0) {
			AlertModule.setError('Existem cores em branco ou inválidas, verifique')
			return
		}

		const tamanhosFiltrados = [] as ItemGradeDeProdutos[]
		this.tamanhosSelecionados.forEach(indiceDoTamanho => {
			if (!this.gradeSelecionada?.itens[indiceDoTamanho]) return
			tamanhosFiltrados.push(this.gradeSelecionada?.itens[indiceDoTamanho])
		})

		tamanhosFiltrados.sort((a, b) => a.ordem - b.ordem)

		if (await this.confirmacaoParaGerarVariantes.mostrarComParametros('Deseja gerar as variantes deste produto neste formato?',
			`Tamanhos: ${tamanhosFiltrados.map(tamanho => tamanho.descricaoItem).join(', ')} <br> Cores: ${this.coresAdicionadas.map(cor => cor.descricao).join(', ')}`)) {
			try {
				this.salvando = true
				this.$emit('confirmado', this.coresAdicionadas.map(cor => cor.descricao), tamanhosFiltrados.map(tamanho => tamanho.descricaoItem))
				this.mostra = false
			} catch (error: any) {
				AlertModule.setError(error)
			} finally {
				this.salvando = false
			}
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if(mostra) return
		this.gradeSelecionada = null
	}

	buscarTituloGrade(grade: GradeDeProdutos) {
		return grade.descricao
	}

	buscarSubTituloGrade(grade: GradeDeProdutos) {
		return grade.itens.map(item => item.descricaoItem).join(', ')
	}

	selecionarGrade(grade: GradeDeProdutos) {
		this.gradeSelecionada = grade

		this.gradeSelecionada.itens.sort((a, b) => a.ordem - b.ordem)

		for (let i = 0;i < grade.itens.length; i++) {
			this.tamanhosSelecionados.push(i)
		}
	}

	async focarCor(indice) {
		setTimeout(() => {
			this.$refs[`cor-${indice}`][0].$children[0].focus()
		})
	}
}


















import { Vue, Component } from 'vue-property-decorator'

@Component({
	directives: {},
})
export default class CampoDeTexto extends Vue  {
	$children !: [
		HTMLInputElement & Vue
	]
}


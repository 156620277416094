














































































































































import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import type { ProdutoDaImportacao } from '@/models/ImportacaoDeNota'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { obrigatorio } from '@/shareds/regras-de-form'
import { fixarCasasDecimais } from '@/shareds/formatadores'
import SeletorDeMarca from '@/components/produto/SeletorDeMarca.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import { round } from '@/shareds/number-utils'
import type { LojaDoPdv } from '@/models'

@Component({
	components: {
		BuscaDeProdutoDropdown,
		SeletorDeMarca,
		CampoDePercentual,
	},
})

export default class ProdutoDaImportacaoDeNota extends Vue {
	@Prop() produto!: ProdutoDaImportacao
	@Prop() indice!: number
	@Prop() loja!: LojaDoPdv
	obrigatorio = obrigatorio
	fixarCasasDecimais = fixarCasasDecimais

	mounted() {
		this.calculaPrecoVarejo()
	}
  
	get tiposDeProdutos(): BuscaDeProdutoDropdown['filtros'] {
		return { tipos: ['Padrão', 'Variante', 'Composto'] }
	}

	formatarNumero(numero) {
		if(numero == null || numero == undefined) {
			return ''
		}
		return this.fixarCasasDecimais(parseFloat(numero), 2)
	}
	
	editarNovoProduto() {
		this.$emit('abrirDialogo', this.produto, this.indice)
	}

	@Watch('produto.margemDeLucro', {deep: true})
	calculaPrecoVarejo() {
		if (!this.produto) return
		const precoCompra = Number(this.produto.nota.vUnCom) || 0
		const margemDeLucro = 1 + (this.produto.margemDeLucro || 0) / 100

		this.produto.precoFinalVarejo = round(
			(precoCompra * margemDeLucro ).toString(),
			2,
		)
	}
}


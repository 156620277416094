
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DataTableDeCrud from '../ui/DataTableDeCrud.vue';
import { DataOptions } from 'vuetify';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindGrupoEconomicoUseCase, SaveFornecedorUseCase } from '@/usecases';
import DialogoDeDetalhesDaVenda from '@/components/venda/DialogoDeDetalhesDaVenda.vue'
import RodapePersonalizado from '../ui/data-tables/RodapePersonalizado.vue';
import axios, {CancelTokenSource } from 'axios'
import { nextTick } from '@/shareds/utils';
import GridDeVendaResumido from '@/views/application/venda/GridDeVendaResumido.vue';
import { GrupoEconomico } from '@/models/GrupoEconomico';
import { removerFormatacaoDeCnpjOuCpf } from '@/shareds/formatadores';

@Component({
	components: {
		DataTableDeCrud,
		DialogoDeDetalhesDaVenda,
		GridDeVendaResumido,
		RodapePersonalizado,
	},
})
export default class ListagemDosGruposEconomicosPorFornecedor extends Vue {
	@Prop() cpfOuCnpjDoFornecedor!: string

	carregando = true
	loading = false
	mostrar = false
	buscando = false

	headers = [
		{ text: 'Grupo Econômico', value: 'descricao' },
		{ text: 'Identificador', value: 'identificador' },
	]

	totalRegistros = -1

	cancelToken: CancelTokenSource | null = null
	gruposExibidos: GrupoEconomico[] = []

	findGrupoEconomicoUseCase = new FindGrupoEconomicoUseCase()
	saveFornecedorUseCase = new SaveFornecedorUseCase()
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	@Watch('mostrar')
	created() {
		this.buscar()
	}

	@Watch('paginacao')
	async buscar() {
		if(!this.cpfOuCnpjDoFornecedor) return
		await nextTick()
		try {
			this.buscando = true
			const params = {
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
			}
			const axiosConfig = {
				cancelToken:axios.CancelToken.source().token,
			}
			const cpfOuCnpjDoFornecedorFormatado = removerFormatacaoDeCnpjOuCpf(this.cpfOuCnpjDoFornecedor)
			const page = await this.findGrupoEconomicoUseCase.listarGruposDoFornecedor(cpfOuCnpjDoFornecedorFormatado, params, axiosConfig)
			this.gruposExibidos = page.content
			this.totalRegistros = page.totalElements

		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	navegar(page) {
		this.paginacao.page += page
		this.buscar()
	}
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"400","scrollable":"","persistent":""},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true),model:{value:(_vm.mostra),callback:function ($$v) {_vm.mostra=$$v},expression:"mostra"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.confirmarSelecao.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',[_vm._v("Informe as variações do produto")]),_c('v-card-text',{staticClass:"px-0"},[_c('v-container',{staticClass:"mb-5"},[_c('v-card',[_c('v-card-title',[_vm._v(" Cores ")]),_c('v-card-text',[_vm._l((_vm.coresAdicionadas),function(cor,indice){return _c('v-row',{key:indice,staticClass:"mr-2 ml-2"},[_c('v-divider'),_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"10"}},[(cor.editando)?_c('CampoDeTexto',{ref:("cor-" + indice),refInFor:true,attrs:{"label":"Descrição"},on:{"blur":function () { return cor.editando = false; }},model:{value:(_vm.coresAdicionadas[indice].descricao),callback:function ($$v) {_vm.$set(_vm.coresAdicionadas[indice], "descricao", $$v)},expression:"coresAdicionadas[indice].descricao"}}):_c('div',[_vm._v(" "+_vm._s(_vm.coresAdicionadas[indice].descricao)+" ")])],1),_c('v-col',{staticClass:"d-flex align-right",attrs:{"sm":"1"}},[(!cor.editando)?_c('v-btn',{attrs:{"icon":""},on:{"click":function () {
										cor.editando = true
										_vm.focarCor(indice)
									}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1),_c('v-col',{staticClass:"d-flex align-right",attrs:{"sm":"1"}},[(!cor.editando)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function () { return _vm.coresAdicionadas.splice(indice, 1); }}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)}),_c('v-row',{staticClass:"mr-2 ml-2"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{staticClass:"d-flex align-center",attrs:{"color":"success","block":"","outlined":""},on:{"click":function () {
											_vm.coresAdicionadas.push({descricao: '', editando: true})
											_vm.focarCor(_vm.coresAdicionadas.length-1)
										}}},[_vm._v(" Adicionar"),_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],2)],1)],1),_c('v-divider'),_c('v-container',{staticClass:"mt-5"},[_c('v-card',[_c('v-card-title',[_vm._v(" Tamanhos ")]),(_vm.gradeSelecionada)?_c('v-card-subtitle',[_vm._v(" Selecionado: "+_vm._s(_vm.gradeSelecionada.descricao)+" ")]):_vm._e(),(!_vm.gradeSelecionada)?_c('v-card-text',[_c('v-row',_vm._l((_vm.gradesEncontradas),function(grade){return _c('v-col',{key:grade.id,style:({
										flexBasis: '100%',
									})},[_c('v-card',{staticClass:"align-center justify-center",attrs:{"color":"success","height":"80"},on:{"click":function($event){return _vm.selecionarGrade(grade)}}},[_c('v-card-title',{staticClass:"align-center justify-center"},[_vm._v(" "+_vm._s(_vm.buscarTituloGrade(grade))+" ")]),_c('v-card-subtitle',{staticClass:"d-flex align-center justify-center mb-10"},[_vm._v(" "+_vm._s(_vm.buscarSubTituloGrade(grade))+" ")])],1)],1)}),1)],1):_c('v-card-text',[_c('v-card',[_c('v-card-subtitle',[_vm._v("Selecione os tamanhos desejados")]),_c('v-card-text',[_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":"","active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.tamanhosSelecionados),callback:function ($$v) {_vm.tamanhosSelecionados=$$v},expression:"tamanhosSelecionados"}},[_vm._l((_vm.gradeSelecionada.itens),function(item,index){return [_c('v-divider',{key:'divider-' + index}),_c('v-list-item',{key:'item-' + index,staticClass:"px-0"},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"sm":"12"}},[_vm._v(" Tamanho ("+_vm._s(item.descricaoItem)+") ")])],1)]}),_c('v-divider')],2)],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"type":"button","text":""},on:{"click":_vm.esconder}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"type":"submit","text":"","color":"success"}},[_vm._v("Confirmar")])],1)],1)],1),_c('Confirmacao',{ref:"confirmacaoParaGerarVariantes",attrs:{"width":"400","persistent":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }